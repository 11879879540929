import React from 'react'
import NewsletterForm from './NewsletterForm'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Button, Heading, Link, Grid, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { default as config } from '../../config.json'
const { pageUrlBuilder } = require('../utils/funcs')

const Footer = () => {

  const intl = useIntl();
  const privacyLinks = pageUrlBuilder(config, 'privacyUID');
  
  return(
  <Box as="footer" backgroundColor="black">
    <Box
      maxW="1030px"
      mx="auto"
      mt='2rem'
      p={{ base:"2rem", lg:"4rem 2rem"}}
      fontFamily="Open Sans"
    >
      <Heading
        as="h5"
        mb={{ base:'1rem', lg:'2rem'}}
        color="brand.cream"
        fontSize={{ base:'xl', lg:'3xl'}}
        fontFamily="Open Sans"
      >
        <FormattedMessage id="footer.join_community" />
      </Heading>
      <Grid
        templateColumns={{  base:"100%", lg:"60% calc(40% - 3rem)" }}
        gap="3rem"
      >
        <Box>
          <Text mb="2rem" minH="54px" fontSize="18px" color="brand.cream" fontFamily="Open Sans">
            <FormattedMessage id="footer.receive_articles" />
          </Text>
          <NewsletterForm />
        </Box>
        <Box >
          <Text mb={{ base:'1rem', lg:'2rem'}} fontSize="18px" color="brand.cream" fontFamily="Open Sans">
          <FormattedMessage id="footer.share_your_thoughts" />
          </Text>
          <Button
            as={ "a" }
            target="_blank"
            fontFamily="Open sans"
            fontSize={{ base: '13px', lg:'14px' }}
            href="https://www.facebook.com/VitalyMalkin/"
            backgroundColor="brand.cream"
            borderRadius="1px"
            textTransform="uppercase"
            whiteSpace='pre-wrap'
            textAlign='center'
            w='100%'
            _hover={{
              backgroundColor:"brand.orange",
              color:"brand.cream"
            }}
          >
            <FormattedMessage id="footer.subscrive_to_vitaly_page" />
          </Button>
        </Box>
      </Grid>
      <Box
        w="100%"
        pt="3rem"
      >
          <Text color="gray.400" textAlign="left" fontFamily="Open Sans">2021&nbsp;-&nbsp;
            <Link
              color="gray.400"
              textDecoration="underline"
              as={GatsbyLink}
              to={ privacyLinks[intl.locale] }
            >
              <FormattedMessage id="footer.privacy.policy.link" />
            </Link>
          </Text>
        </Box>
    </Box>
  </Box>)
}

export default Footer
