import React from 'react'
import { Box } from '@chakra-ui/react'

const Hamburger = (props) => (
    <Box
        display={{ base:'flex', lg:'none' }}
        w='40px'
        h='40px'
        p='.5rem'
        pr='0'
        justifyContent='space-between'
        flexWrap='wrap'
        onClick={ props.onClick }
    >
        <Box
            backgroundColor={props.color}
            h='2px'
            w='100%'
        />
        <Box
            backgroundColor={props.color}
            h='2px'
            w='100%'
        />
        <Box
            backgroundColor={props.color}
            h='2px'
            w='100%'
        />
    </Box>
)

export default Hamburger