import React from 'react'
import { Box } from '@chakra-ui/react'

const HamburgerClose = (props) => (
    <Box
        w='40px'
        h='40px'
        display={{ base:'flex', lg:'none' }}
        p='.5rem'
        pr='0'
        justifyContent='space-between'
        flexWrap='wrap'
        onClick={ props.onClick }
        position="relative"
    >
        <Box
            backgroundColor='white'
            h='2px'
            w='90%'
            transform='rotate(45deg)'
            position="absolute"
            top='1rem'
        />
        <Box
            backgroundColor='white'
            h='2px'
            w='90%'
            transform='rotate(-45deg)'
            position='absolute'
            top='1rem'
        />
    </Box>
)

export default HamburgerClose