import React, { useState } from 'react'
import { useIntl } from 'react-intl';

import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import Messages from '../locales/messages'

import ReactGA from 'react-ga'
ReactGA.initialize( process.env.GA_ID )

const NewsletterForm = ( props ) => {
    const intl = useIntl()
    const { color } = props
    const [isEmailRecord, setEmailRecord] = useState(false);


    const submitNewsletterForm = (e) => {
        console.log( 'publish in MC' )
        e.preventDefault()
        let email = e.target.querySelector('input').value
        console.log(email)
        console.log('submit')

        e.preventDefault();
        addToMailchimp(email ,
                // {lang:'fr', 'book':'book_1'}
                {
                    BOOK: 'generic',
                    LANG: intl.locale
                }
            ) // listFields are optional if you are only capturing the email address.
            .then(data => {
                ReactGA.event({
                    category: 'Newsletter',
                    action: 'Subscribe',
                    label: 'Footer'
                  });
                if (data.result === 'success') {
                    setEmailRecord(true)
                }
                if (data.result === 'error') {
                    setEmailRecord(true)
                }
            })
            .catch(() => {

            })
    }
    return (
        <Box
            as="form"
            onSubmit={(e) => { submitNewsletterForm(e) }}
        >
            <Flex wrap="wrap">
                {!isEmailRecord ?
                    <>
                        <Box
                            mr={{ base:0, lg: "1rem"}}
                            w={{ base:'100%', lg:'auto'}}
                            mb={{ base:'1rem', lg:'auto'}}
                        >
                            <Input
                                type="email"
                                required
                                id="email"
                                placeholder={ Messages[intl.locale]? Messages[intl.locale]['footer.your_email'] : 'Email' }
                                backgroundColor="transparent"
                                border="none"
                                borderRadius="none"
                                borderBottom="solid 3px"
                                borderBottomColor="#64615D"
                                color={ color === 'orange' ? 'gray.700' : 'white' }
                                minW="250px"
                                _focus={{
                                    outline:'none'
                                }}
                            />
                        </Box>
                        <Box
                            w={{ base:'100%', lg:'auto'}}
                        >
                            <Button
                                fontSize='14px'
                                type="submit"
                                whiteSpace='pre-wrap'
                                textAlign='center'
                                backgroundColor={ color === 'orange' ? 'brand.orange' : "brand.cream" }
                                border='solid 1px'
                                borderColor='transparent'
                                color={ color === 'orange' ? 'white' : 'gray.700' }
                                borderRadius="1px"
                                fontFamily="Open sans"
                                width='100%'
                                textTransform="uppercase"
                                _hover={{
                                    backgroundColor: color === 'orange' ? 'white' : "brand.orange",
                                    color: color === 'orange' ? 'brand.orange' : "white",
                                    border: 'solid 1px',
                                    borderColor: color === 'orange' ? 'orange' : 'transparent'

                                }}
                            >
                                <FormattedMessage id="footer.subscrive_to_vitaly_newsletter" />
                            </Button>
                        </Box>
                    </>
                    :
                    <Text color="brand.cream">
                        <FormattedMessage id="footer.subscrive_to_vitaly_newsletter_success" />
                    </Text>
                }
            </Flex>
        </Box>
    )
}

export default NewsletterForm