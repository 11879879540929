import React, { useState, useEffect } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import { default as config } from '../../config.json'
const { pageUrlBuilder } = require('../utils/funcs')
import { useIntl } from 'react-intl'

// import {isCookiesApproved} from '../utils/cookiesApproves'

// console.log( 'isCookiesApproved', isCookiesApproved )

const Banner = () => {
  const [isCookiesApproves, setCookiesApproved] = useState(true);
  const [isBannerVisible, setBannerVisible] = useState(true);

  const recordCookieApproved = () => {
    localStorage.setItem('isCookiesApproves', 'true')
    setCookiesApproved(true);
  }

  useEffect(() => {
    setCookiesApproved(Boolean(localStorage.getItem('isCookiesApproves')))
  }, [])

  const intl = useIntl()
  
  const privacyLinks = pageUrlBuilder(config, 'privacyUID');

  return (
    !isCookiesApproves ?
    <CSSTransition
      in={isBannerVisible}
      timeout={250}
      classNames="mobileNav"
      unmountOnExit
      onExited={() => recordCookieApproved(true)}
    >
      <Flex
        zIndex='9999999'
        boxShadow='sm'
        position="fixed"
        bottom='1rem'
        left={{ base:'1rem', lg:0 }}
        right={{ base:'1rem', lg:'initial' }}
        justifyContent="center"
        w={{ lg:'100vw' }}
        wrap={{ base:'wrap', lg:'inherit'}}
      >
      <Flex
        borderRadius='3px'
        backgroundColor='black'
        border='solid 3px'
        borderColor='white'
        color='white'
        wrap={{ base:'wrap', lg:'inherit'}}

        fontFamily="Open Sans"
        p='1rem'
        w={{ lg:'1030px'}}
        // maxW='600px'
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setBannerVisible(false)}

        // wrap={{ base:'wrap', lg:'nowrap'}}
      >
        <Text>
          <FormattedMessage id="cookie.banner" />{' '}
          <Link
            as={GatsbyLink}
            to={ privacyLinks[intl.locale] }
            textDecor="underline"
          >
            <FormattedMessage id="cookie.learn_more" />
          </Link>
        </Text>
        <Button
          variant='outline'
          backgroundColor="brand.cream"
          color='black'
          ml={{ lg:'2rem' }}
          mt={{ base:'.5rem', lg:0 }}
          px='1rem'
          w={{ base:'100%', lg:'auto'}}
          _hover={{
            backgroundColor: 'black',
            border: 'solid 1px',
            borderColor: 'brand.cream',
            color: 'brand.cream'
          }}
          onClick={() => setBannerVisible(false)}
        >
          <FormattedMessage id="cookie.got_it" />
        </Button>
      </Flex>
      </Flex>
    </CSSTransition>
    : null

  )
}
export default Banner
