import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { Box, List, ListItem, Stack } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useIntl } from 'react-intl'

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getPageLink = (alternateLanguages, lang) =>
  linkResolver(alternateLanguages.find(({ lang: language }) => lang === language));

const LanguageSwitcher = ({ activeDocMeta, color }) => {
  const { alternateLanguages = [] } = activeDocMeta;
  const locale = useIntl()['locale']
  const [isSubLangVisible, setSubLangVisible] = useState(false);

  return (
    <>
      <Box
        display={{ lg: 'none' }}
      >
        <Box
          display={{ base: 'block', lg: 'none' }}
          w='30px'
          h='1px'
          backgroundColor='white'
          my="2rem"
        />
        <Stack
          isInline
          color='white'
          spacing="1rem"
        >
          {alternateLanguages.map((doc) =>
            <Box
              key={`deskop-lang-switch-${doc.lang}`}
              onClick={() => { navigate(linkResolver(doc)) }}
            >
              {capitalize(doc.lang.slice(0, 2))} --
            </Box>
          )}
        </Stack>
      </Box>
      <Box
        display={{ base: 'none', lg: 'block' }}
        color={ color === 'dark' ? 'white' : 'brand.orange' }
        borderRadius='2px'
        h='22px'
        ml={{ base: 0, lg: '2rem' }}
        mt={{ base: '2rem', lg: 0 }}
        px='.45rem'
        cursor='pointer'
        // borderBottomColor={ isSubLangVisible ? "white": 'brand.orange'}
        onClick={() => { setSubLangVisible(!isSubLangVisible) }}
        position="relative"
        w={{ base: '60px', lg: 'auto' }}
      >
        {/* <pre>
          { JSON.stringify( activeDocMeta, null, 2 )}
        </pre>
        <div>
          {
            JSON.stringify(
              linkResolver( activeDocMeta.alternateLanguages[0] ),
              null,
              2
            )
        }
        </div> */}
        {/* {languagesCode[currentLang]} */}
        { locale.toUpperCase() }
        <ChevronDownIcon ml='.2rem' borderRadius='3px' name="chevron-down" />
        {isSubLangVisible ?
          <Box
            position="absolute"
            borderRadius="3px"
            border="solid 1px"
            borderColor={ color === 'dark' ? 'white' : 'brand.orange' }
            zIndex="tooltip"
            left="-10px"
            top="25px"
            backgroundColor={color === 'dark' ? 'brand.orange' : 'brand.cream'}

          >

            <List background='brand.cream' textTransform='uppercase'>
              {alternateLanguages.map(alternateDoc =>
                // <div>{ lang.lang }</div>
                <ListItem
                  key={`deskop-lang-switch-${alternateDoc.lang}`}
                  p='.25rem 1rem'
                  color={ 'brand.orange' }

                  // backgroundColor="brand.orange"
                  _hover={{ background: 'white', color: 'brand.orange' }}
                  onClick={() => { navigate(linkResolver(alternateDoc)) }}>
                  {alternateDoc.lang.slice(0,2)}
                </ListItem>

              )}
            </List>
          </Box>
          : null}
      </Box>
    </>
  )
}

export default LanguageSwitcher
